<template>
  <!-- 计费项目 -->
  <div class="carterExpenseManage">
    <el-button
      v-if="listFind('添加')"
      type="primary"
      size="small"
      @click="addBtnFun"
      >添加</el-button
    >
    <Table
      :table-data="tableData"
      :title-name="titleName"
      :operation="true"
      :col-class-type="true"
      operation-width="150"
    >
      <template slot-scope="scope">
        <div>
          <template>
            <el-button
              v-if="listFind('编辑')"
              type="text"
              size="small"
              sort="primary"
              @click="updateBtn(scope.scopeRow)"
              >编辑</el-button
            >
          </template>
          <template>
            <el-button
              v-if="listFind('删除')"
              type="text"
              size="small"
              sort="danger"
              @click="deleteFun(scope.scopeRow)"
              >删除</el-button
            >
          </template>
        </div>
      </template>
    </Table>
    <charterPopup
      v-if="show"
      :header-text="headerText"
      :disabled="disabled"
      @confirmFun="confirmFun"
      @closePopupFun="
        () => {
          this.show = false;
          this.clearForm();
        }
      "
    >
      <el-form
        ref="Form"
        :model="Form"
        :rules="rules"
        label-width="140px"
        label-position="right"
        class="demo-form dialog-form"
      >
        <el-form-item label="费用名称：" prop="costName">
          <el-input
            v-model="Form.costName"
            maxlength="10"
            :disabled="disabled"
            placeholder="最多10个字"
          ></el-input>
        </el-form-item>
        <el-form-item label="单位：" prop="unit">
          <el-input
            v-model="Form.unit"
            maxlength="10"
            :disabled="disabled"
            placeholder="最多10个字"
          ></el-input>
        </el-form-item>
        <el-form-item label="费用描述：" prop="modelTypeDescription">
          <el-input
            v-model="Form.modelTypeDescription"
            :disabled="disabled"
            placeholder="请填写费用描述，最多500字"
            :autosize="{ minRows: 2, maxRows: 6 }"
            resize="none"
            type="textarea"
            maxlength="500"
          ></el-input>
        </el-form-item>
      </el-form>
    </charterPopup>
  </div>
</template>

<script>
import {
  postCharteredCostProjectAPI,
  delCharteredCostProjectAPI,
  putCharteredCostProjectAPI,
  getCharteredCostProjectListAPI,
} from "@/api/lib/api.js";
export default {
  data() {
    return {
      disabled: "",
      popupStatus: "",
      Form: {
        costName: "",
        unit: "",
        modelTypeDescription: "",
      },
      rules: {
        costName: [
          { required: true, message: "请输入费用名称", trigger: "blur" },
        ],
        unit: [{ required: true, message: "请输入单位", trigger: "blur" }],
        modelTypeDescription: [
          { required: true, message: "请输入费用描述", trigger: "blur" },
        ],
      },
      headerText: "",
      show: false,
      tableData: [],
      titleName: [
        {
          title: "费用名称",
          props: "costName",
        },
        {
          title: "费用描述",
          props: "modelTypeDescription",
        },
        {
          title: "单位",
          props: "unit",
        },
      ],
    };
  },
  mounted() {
    this.renderData();
  },
  methods: {
    clearForm() {
      this.Form = {
        costName: "",
        unit: "",
        modelTypeDescription: "",
      };
    },
    // 点击添加按钮
    addBtnFun() {
      this.disabled = false;
      this.popupStatus = "add";
      this.show = true;
      this.headerText = "添加";
    },
    // 点击编辑按钮
    updateBtn({ id, unit, costName, modelTypeDescription }) {
      this.headerText = "编辑";
      this.disabled = false;
      this.popupStatus = "update";
      this.Form = { id, unit, costName, modelTypeDescription };
      this.show = true;
    },
    // 更新编辑数据
    updateFun(obj) {
      putCharteredCostProjectAPI(obj).then((res) => {
        if (res.code == "SUCCESS") {
          this.$message.success("修改成功");
          this.renderData();
        }
      });
    },
    deleteFun(params) {
      const h = this.$createElement;
      this.$msgbox({
        title: "提示",
        type: "warning",
        message: h("p", null, [h("span", null, "确认删除该计费项目？")]),
        showCancelButton: true,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(() => {
          delCharteredCostProjectAPI({ id: params.id }).then((res) => {
            if (res.code == "SUCCESS") {
              this.$message.success("删除成功");
              this.renderData();
            }
          });
        })
        .catch(() => {});
    },
    // 表格数据渲染
    renderData() {
      getCharteredCostProjectListAPI().then((res) => {
        if (res.code == "SUCCESS") {
          this.tableData = res.data;
        }
      });
    },
    // 确定按钮回调
    confirmFun() {
      this.$refs.Form.validate((valid) => {
        if (valid) {
          switch (this.popupStatus) {
            case "add":
              // 新增接口
              postCharteredCostProjectAPI(this.Form).then((res) => {
                if (res.code == "SUCCESS") {
                  this.$message.success("新增成功");
                  this.clearForm();
                  this.renderData();
                }
              });
              break;
            case "update":
              this.updateFun(this.Form);
              break;
          }
          this.show = false;
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.carterPackageManage {
  box-shadow: 0 0 4px 0 rgba($color: #343434, $alpha: 0.1);
  border-radius: 4px;
  padding: 16px 20px;
  background: #ffffff;
}
.Table {
  margin-top: 14px;
}
</style>
